import React from "react";
import queryString from "query-string";
import SEO from "@src/components/SEO";
import { useRedirectHandler } from "@src/utils/useRedirectHandler";
import window from "global/window";

const Settings = () => {
  const parsedLocation = queryString.parse(window?.location?.search || "");
  const { target } = parsedLocation;

  useRedirectHandler(() => {
    if (target === "security") {
      return `https://teach.classdojo.com/#/launchpad?settingsTab=security`;
    } else if (target === "notifications") {
      return `https://teach.classdojo.com/#/launchpad?settingsTab=messaging`;
    } else {
      return `https://teach.classdojo.com/#/launchpad?settingsTab=profile`;
    }
  });

  return (
    <>
      <SEO noindex={true} />
    </>
  );
};
export default Settings;
